// extracted by mini-css-extract-plugin
export var faq = "faq-module--faq--rxng6";
export var faqBan = "faq-module--faqBan--gF5DI";
export var faqBanButton = "faq-module--faqBanButton--WLU+q";
export var faqBanInner = "faq-module--faqBanInner--l74bu";
export var faqBanPara = "faq-module--faqBanPara--zGSmV";
export var faqBanText = "faq-module--faqBanText---gS6E";
export var faqBanTitle = "faq-module--faqBanTitle--vpcfi";
export var faqItem = "faq-module--faqItem--CUV4+";
export var faqItemContent = "faq-module--faqItemContent--55oLn";
export var faqItemContentText = "faq-module--faqItemContentText--1gyED";
export var faqItemIcon = "faq-module--faqItemIcon---J4zW";
export var faqItemLine = "faq-module--faqItemLine--dpKmM";
export var faqItemLink = "faq-module--faqItemLink--9weWh";
export var faqItemLinkIcon = "faq-module--faqItemLinkIcon--92aPC";
export var faqItemLinkLi = "faq-module--faqItemLinkLi--PqApR";
export var faqItemLinkText = "faq-module--faqItemLinkText--RdXwk";
export var faqItemText = "faq-module--faqItemText--6zv28";
export var faqItemTitle = "faq-module--faqItemTitle--306xV";
export var faqItemTitleText = "faq-module--faqItemTitleText--wxsWc";
export var faqItemTitleTextContent = "faq-module--faqItemTitleTextContent--aJRZY";
export var faqItemTitleTextIndex = "faq-module--faqItemTitleTextIndex--EQehZ";
export var faqItems = "faq-module--faqItems--wi71x";
export var faqList = "faq-module--faqList--K6ReM";
export var faqTitle = "faq-module--faqTitle--SjJvC";
export var loading = "faq-module--loading--fipzm";
export var mobileSeedcredLink = "faq-module--mobileSeedcredLink--9rsbm";